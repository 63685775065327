import CustomIconButton from '../ui/custom-button.component';
import { Close } from '@mui/icons-material';
import { Dialog, Box, Card, CardMedia, DialogContent } from '@mui/material';

export default function FullScreenDialog({ isFullScreen, setIsFullScreen, photo, imageInfo }) {

    return (
        <Dialog
            fullScreen
            open={isFullScreen}
            onClose={() => setIsFullScreen(false)}
            sx={{
                '.MuiPaper-root': {
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    zIndex: 0,
                    '&::before': {
                        content: '""',
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        // backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.52), rgba(64, 64, 64, 0.73)), url(${photo.featured_image})`,
                        // backgroundSize: '100% 100%',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        filter: 'blur(15px)',
                        zIndex: 0,
                        backgroundColor: 'black',
                    },
                }
            }}
        >
            <DialogContent sx={{ m: 0, p: 0 }}>
                <Card sx={{ borderRadius: 0, height: '100%', backgroundColor: '#1c1c1c', }}>
                    <Box sx={{ position: 'absolute', right: 0, top: 0, py: imageInfo?.type === 'landscape' ? 1 : 4, pr: imageInfo?.type === 'landscape' ? 1 : 1, zIndex: 999 }}>
                        <CustomIconButton icon={<Close />} onClick={() => {
                            setIsFullScreen(false)
                        }} />
                    </Box>

                    <Box sx={{ position: 'relative', height: '100%', }}>
                        <CardMedia
                            alt="image"
                            component="img"
                            image={photo?.featured_image}
                            sx={{ objectFit: 'contain', width: '100%', height: '100%', }}
                        />
                    </Box>
                </Card>
            </DialogContent>
        </ Dialog>
    );
}