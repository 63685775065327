import React, { useContext, useMemo, useState } from "react";

let mapHeight = "100vh";

const PhotographyContext = React.createContext();

export function usePhotographsContext() {
  return useContext(PhotographyContext);
}

export function PhotographyProvider({ children }) {
  const [map, setMap] = useState();
  const [zoomLevel, setZoomLevel] = useState(8);
  const [currentMapCenter, setCurrentMapCenter] = useState({ latitude: null, longitude: null });
  const [totalPhotos, setTotalPhotos] = useState(0);
  const [clickedTags, setClickedTags] = useState([]);
  const [filter, setFilter] = useState("newest");
  const [isPhotographsDrawerOpened, setIsPhotographsDrawerOpened] =
    useState(false);

  const [showPlacesOnMap, setShowPlacesOnMap] = useState(false);
  const [clickedPhotographyId, setClickedPhotographyId] = useState();

  const [clickedPlaceId, setClickedPlaceId] = useState(null);
  const [isPlacesDrawerOpened, setIsPlacesDrawerOpened] = useState(false);

  const [isClickedPhotoDrawerOpened, setIsClickedPhotoDrawerOpened] =
    useState(false);

  const [showMobileFilter, setShowMobileFilter] = useState(false);

  function onPhotographyClickedHandler(id) {
    setClickedPhotographyId(id);
    setIsPhotographsDrawerOpened(true);
  }

  useMemo(() => {
    if (document.getElementById("root")) {
      const container = document.getElementById("root");
      let height = container.style.height;
      if (height) {
        mapHeight = height;
      }
    }
  }, []);

  const value = {
    isPhotographsDrawerOpened,
    setIsPhotographsDrawerOpened,
    clickedPhotographyId,
    setClickedPhotographyId,
    onPhotographyClickedHandler,
    mapHeight,
    map,
    setMap,
    clickedTags,
    setClickedTags,
    clickedPlaceId,
    setClickedPlaceId,
    isPlacesDrawerOpened,
    setIsPlacesDrawerOpened,
    zoomLevel,
    setZoomLevel,
    totalPhotos,
    setTotalPhotos,
    isClickedPhotoDrawerOpened,
    setIsClickedPhotoDrawerOpened,
    showMobileFilter,
    setShowMobileFilter,
    filter,
    setFilter,
    showPlacesOnMap,
    setShowPlacesOnMap,
    currentMapCenter, setCurrentMapCenter
  };

  return (
    <PhotographyContext.Provider value={value}>
      {children}
    </PhotographyContext.Provider>
  );
}
