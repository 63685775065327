import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Favorite, GpsFixedOutlined, RoomOutlined } from "@mui/icons-material";
import { useAuth } from "../../contexts/auth-context";
import { Box, Typography, CardMedia, Grid, Card, CardContent, IconButton, Skeleton } from "@mui/material";

const PhotographyCard = ({ photo, refetch }) => {
    const navigate = useNavigate();
    const { favoriteHandler } = useAuth();

    const [isImageLoaded, setIsImageLoaded] = useState(false);

    return (
        <Grid item xl={3} md={4} sm={6} xs={12}>
            <Card sx={{ boxShadow: 'unset', position: 'relative', cursor: 'pointer' }}>
                {photo?.distance &&
                    <Box sx={{
                        top: 12, left: 12, display: 'flex', position: 'absolute', alignItems: 'center',
                        backgroundColor: 'rgba(255,255,255,0.7)', pl: 0.5, pr: 1, py: '2px', borderRadius: 2
                    }}>
                        <RoomOutlined fontSize="small" sx={{ color: 'grey.800', width: '0.95em', height: '0.9em' }} />
                        <Typography variant="subtitle2" color={'grey.800'}>{Math.round(photo?.distance)} km</Typography>
                    </Box>
                }
                <Box
                    onClick={() => favoriteHandler(photo?.id, refetch, navigate)}
                    sx={{ top: 9, right: 9, display: 'flex', position: 'absolute', alignItems: 'center', }}>
                    <IconButton sx={{ color: 'red', p: '3px' }} aria-label="like" >
                        <Favorite
                            fontSize="medium"
                            sx={{
                                strokeWidth: 2,
                                stroke: '#FFFFFF',
                                fill: photo?.is_liked ? 'red' : 'rgba(50, 50, 50,0.8)',
                            }}
                        />
                    </IconButton>
                </Box>

                <Box>
                    {photo?.featured_image &&
                        <CardMedia
                            component="img"
                            alt={photo?.title}
                            image={photo?.featured_image}
                            onLoad={(e) => { setIsImageLoaded(true); }}
                            onClick={() => navigate(`/photography/${photo?.id}`)}
                            sx={{ display: !isImageLoaded ? 'none' : 'initial', height: '230px', borderRadius: 5, }}
                        />
                    }

                    {((photo?.featured_image && !isImageLoaded) || !photo?.featured_image) &&
                        <Box sx={{ position: "relative" }}>
                            <Skeleton variant="rectangular" sx={{ height: "230px", borderRadius: 5 }} />
                        </Box>
                    }
                </Box>

                <CardContent sx={{ p: 0, '&:last-child': { pb: 2 } }} onClick={() => navigate(`/photography/${photo?.id}`)}>
                    <Typography sx={{ textDecoration: 'none', color: 'unset', fontSize: '16px', mt: '5px', mb: '2px', cursor: 'pointer', pl: '5px' }}>
                        {photo?.title}
                    </Typography>

                    <Typography variant="caption" component={'p'} sx={{ lineHeight: '1.4', color: 'dimgray', pl: '5px' }}>
                        {[photo?.author, photo?.subtitle].filter(Boolean).join(', ')}
                    </Typography>
                </CardContent>
            </Card>
        </Grid>
    );
};

export default PhotographyCard;