import { useAuth } from '../../../contexts/auth-context';
import { Avatar, ListItemIcon, MenuItem, MenuList, Paper, Box, Typography } from '@mui/material';
import { ExitToApp, FavoriteBorder, VpnKeyOutlined } from '@mui/icons-material';
import { NavLink, useLocation } from 'react-router-dom';

const Sidemenu = () => {
    const location = useLocation();
    const { currentUser, setCurrentUser, setCurrentToken } = useAuth();

    return (
        <Paper sx={{ p: 4, borderRadius: '15px' }} className='side-menu' elevation={3}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', }}>
                <Avatar alt={currentUser?.name} src={currentUser?.avatar_image} sx={{ width: 64, height: 64, mb: 2 }} />
                <Typography variant='h6' align='center'>
                    {currentUser?.name}
                </Typography>
                <Typography component={NavLink} to='/settings' variant='caption' gutterBottom sx={{ textDecoration: 'none', cursor: 'pointer', color: 'gray' }} align='center'>
                    edit
                </Typography>
            </Box>

            <MenuList>
                <MenuItem component={NavLink} to='/favorites' sx={{ px: { sm: 0, xl: 1 }, fontWeight: location.pathname === ('/favorites') && 600 }}>
                    <ListItemIcon>
                        <FavoriteBorder />
                    </ListItemIcon>
                    Favorites
                </MenuItem>
                <MenuItem component={NavLink} to='/settings-password' sx={{ px: { sm: 0, xl: 1 }, fontWeight: location.pathname === ('/settings-password') && 600 }}>
                    <ListItemIcon>
                        <VpnKeyOutlined />
                    </ListItemIcon>
                    Password
                </MenuItem>
                <MenuItem sx={{ px: { sm: 0, xl: 1 }, }} onClick={() => { localStorage.removeItem('dataToken'); setCurrentUser(''); setCurrentToken('') }}>
                    <ListItemIcon>
                        <ExitToApp />
                    </ListItemIcon>
                    Exit
                </MenuItem>
            </MenuList>
        </Paper>
    );
};

export default Sidemenu;
