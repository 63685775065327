import { useNavigate } from 'react-router-dom';
import { useState, useRef, useEffect } from 'react';
import { useAuth } from '../../contexts/auth-context';
import { getImageInfo } from '../../helpers/image-helper';
import { Card, CardMedia, Box, Grid, Skeleton } from '@mui/material';
import CustomIconButton from '../ui/custom-button.component';
import FullScreenDialog from './fullscreen-dialog.component';
import { useSettings } from '../../contexts/settings-context';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { Fullscreen, Share, Favorite, Close } from '@mui/icons-material';
import BackButton from '../ui/back-button.component';

const PhotographyCarousel = ({ photo, refetch, openShareModal }) => {
    const handle = useFullScreenHandle();
    const navigate = useNavigate();
    const { isMobile } = useSettings();
    const { favoriteHandler } = useAuth();

    const imageRef = useRef(null);
    const [isIOS, setIsIOS] = useState(false);
    const [imageInfo, setImageInfo] = useState();
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [isLoadingInfo, setIsLoadingInfo] = useState(true);
    const [isImageLoaded, setIsImageLoaded] = useState(false);

    useEffect(() => {
        const fetchImageType = async () => {
            try {
                const result = await getImageInfo(photo?.featured_image);
                setImageInfo(result);
                setIsLoadingInfo(false);
            } catch (error) {
                setImageInfo('');
                setIsLoadingInfo(false);
            }
        };

        if (photo && photo.featured_image) {
            fetchImageType();
        }

        setIsIOS(/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream);
    }, [photo]);

    const HANDLE_ACTIVE_HEIGHT = (handle?.active ? '100%' : ((isMobile && imageInfo?.type === 'landscape') ? '100%' : (imageInfo?.type === 'landscape' ? '100%' : (isMobile ? '85vh' : '90vh'))));

    return (
        <Grid item xs={12}>
            {(isFullScreen === true) ? (
                <FullScreenDialog isFullScreen={isFullScreen} setIsFullScreen={setIsFullScreen} photo={photo} imageInfo={imageInfo} />
            ) :
                <Card sx={{ backgroundColor: '#1c1c1c', borderRadius: 0 }}>
                    <FullScreen handle={handle}>
                        <Box
                            sx={{
                                position: 'relative',
                                overflow: 'hidden',
                                height: isMobile ? '100%' : HANDLE_ACTIVE_HEIGHT,
                                maxHeight: isMobile ? HANDLE_ACTIVE_HEIGHT : '100%',
                                '&::before': {
                                    height: isMobile ? '100%' : HANDLE_ACTIVE_HEIGHT,
                                    maxHeight: isMobile ? HANDLE_ACTIVE_HEIGHT : '100%',
                                    content: '""',
                                    position: 'absolute',
                                    width: '100%',
                                    backgroundImage: handle?.active ? 'unset' : `linear-gradient(rgba(0, 0, 0, 0.52), rgba(64, 64, 64, 0.73)), url(${photo.featured_image})`,
                                    backgroundSize: '100% 100%',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center',
                                    filter: 'blur(10px)',
                                    zIndex: handle?.active ? -1 : 0,
                                    backgroundColor: handle?.active ? 'black' : 'initial',
                                },
                            }}
                            ref={imageRef}
                        >
                            <Box sx={{ height: '100%' }}>
                                {handle?.active ? null :
                                    <Box sx={{ position: 'absolute', top: 0, left: 0, p: 2, zIndex: 1, }}>
                                        <BackButton sx={{ color: 'white', backgroundColor: 'rgba(0, 0, 0, 0.6)' }} />
                                    </Box>
                                }
                                {handle?.active ?
                                    <Box sx={{ position: 'absolute', top: 0, right: 0, p: 2, zIndex: 1 }}>
                                        <CustomIconButton icon={<Close />} onClick={handle.exit} />
                                    </Box>
                                    : null
                                }

                                {handle?.active ?
                                    <CardMedia
                                        alt="image"
                                        component="img"
                                        image={photo?.featured_image}
                                        sx={{ objectFit: 'contain', width: '100%', height: '100%', margin: 'auto' }}
                                    /> :
                                    <Box sx={{ position: 'relative', height: '100%', maxHeight: (isMobile ? '85vh' : '90vh') }}>

                                        {((photo?.featured_image && !isImageLoaded && isLoadingInfo) || !photo?.featured_image) &&
                                            <Box sx={{ position: "relative" }}>
                                                <Skeleton variant="rectangular" sx={{ height: (isMobile ? '85vh' : '90vh') }} />
                                            </Box>
                                        }

                                        <CardMedia
                                            alt="image"
                                            component="img"
                                            image={photo?.featured_image}
                                            onLoad={(e) => {
                                                setIsImageLoaded(true)
                                            }}
                                            sx={{
                                                width: '100%',
                                                height: isLoadingInfo ? '100%' : HANDLE_ACTIVE_HEIGHT,
                                                objectFit: 'contain',
                                            }}
                                        />


                                        {handle?.active ? null :
                                            <Box sx={{ position: 'absolute', bottom: 0, right: 0, p: 2, }}>
                                                <CustomIconButton icon={<Fullscreen />} buttonStyle={{ mr: 1 }}
                                                    onClick={() => { (isMobile && isIOS) ? setIsFullScreen(true) : handle.enter(); }}
                                                />
                                                <CustomIconButton icon={<Share />} onClick={() => openShareModal(photo)} buttonStyle={{ mr: 1 }} />
                                                <CustomIconButton icon={<Favorite />} onClick={() => favoriteHandler(photo?.id, refetch, navigate)} iconStyle={{
                                                    fill: photo?.is_liked ? 'red' : 'white'
                                                }} />
                                            </Box>
                                        }
                                    </Box>
                                }
                            </Box>
                        </Box>
                    </FullScreen>
                </Card>}
        </Grid >
    );
};

export default PhotographyCarousel;
